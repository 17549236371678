import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { MediaQuery } from "@outplayed/responsive";
import SkillPath from "@ugg/shared/components/common/Skills/SkillPath";
import ChampionSkillWithLabel from "@ugg/shared/components/common/Skills/ChampionSkillWithLabel";
import { getPercetange, numberWithCommas } from "@ugg/shared/utils/math";
import { getTierColor } from "@ugg/shared/utils/tier-helpers";
import { ReactComponent as ArrowRight } from "@ugg/shared/assets/svg/arrow-right.svg";
import { OverviewInfo } from "@ugg/shared/api/data-parser/champions/overview";

interface SkillsProps {
  className?: string;
  championId: number;
  skillPriority: NonNullable<OverviewInfo["rec_skills"]>;
  skillPath: OverviewInfo["rec_skill_path"];
  evolutionPath?: OverviewInfo["rec_skill_evolve_path"] | undefined | null;
}

export default function Skills(props: SkillsProps) {
  const { t } = useTranslation();
  const { className, championId, skillPriority, skillPath, evolutionPath } = props;

  const classNames = classnames("content-section", "content-section_no-padding", "recommended-build_skills", className);
  const skillWinRate = skillPath && getPercetange(skillPath.wins, skillPath.matches, 2);
  const winRateColor = skillWinRate && getTierColor(skillWinRate);

  const skillEvolution = evolutionPath && evolutionPath?.slots?.length > 0 && (
    <div className="evolution-path">
      <div className="evolution-label">Evolution Order:</div>
      {evolutionPath.slots.map((skill, index) => (
        <React.Fragment key={index}>
          <ChampionSkillWithLabel
            className="evolution-skill"
            label={skill}
            labelPosition="bottomRight"
            championId={championId}
            skillSlot={skill}
            ssr
          />
          {index !== evolutionPath.slots.length - 1 && <ArrowRight className="arrow-right" />}
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div className={classNames}>
      <div className="content-section_content skill-priority">
        <div className="content-section_header">{t("Skill Priority")}</div>
        <div className="skill-priority_content">
          <div className="skill-priority-path">
            {skillPriority.slots.map((skill, index) => (
              <React.Fragment key={index}>
                <ChampionSkillWithLabel
                  label={skill}
                  labelPosition="bottomCenter"
                  championId={championId}
                  skillSlot={skill}
                  ssr
                />
                {index !== 2 && <ArrowRight className="arrow-right" />}
              </React.Fragment>
            ))}
          </div>
          {skillWinRate !== null && (
            <div style={{ marginTop: 20, textAlign: "center" }}>
              <div className={`winrate ${winRateColor}`}>
                <span>{`${skillWinRate}% `}</span>WR
              </div>
              <div className="matches">{`${skillPath && numberWithCommas(skillPath.matches)} Matches`}</div>
            </div>
          )}
        </div>
      </div>
      <div className="content-section_content skill-path-block">
        <div className="content-section_header">
          <div className="main-header">
            <span style={{ marginRight: 12 }}>{t("Skill Path")}</span>
            <MediaQuery min="TABLET" max="DESKTOP_LARGE">
              <span className="content-section_blurb">Most popular ability leveling order</span>
            </MediaQuery>
          </div>
          {skillEvolution && (
            <MediaQuery min="TABLET" max="DESKTOP_LARGE">
              {skillEvolution}
            </MediaQuery>
          )}
        </div>
        {skillEvolution && (
          <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
            <div className="mobile-evolution-path">{skillEvolution}</div>
          </MediaQuery>
        )}
        <SkillPath championId={championId} skillOrder={(skillPath && skillPath.slots) || []} skillNames includePassive rec ssr />
      </div>
    </div>
  );
}
