import React from "react";
import { Item } from "@outplayed/tooltips";
import { ReactComponent as ArrowRight } from "@ugg/shared/assets/svg/arrow-right.svg";

type DupeItem = { id: number; count: number };
export function stackDuplicateItems(items: number[]): Array<DupeItem> {
  if (!Array.isArray(items)) {
    return [];
  }

  return items.reduce((acc, cur) => {
    const dupeIndex = acc.findIndex((item) => cur === item.id);
    if (dupeIndex < 0) {
      // New item to list
      const newItem: DupeItem = {
        id: cur,
        count: 1,
      };
      acc.push({ ...newItem });
    } else {
      // Update count
      acc[dupeIndex].count++;
    }

    return acc;
  }, [] as Array<DupeItem>);
}

export function displayItems(
  items: number[],
  spriteSize: number,
  options?: {
    pathArrows?: boolean;
    errorMsg?: string;
    patch?: string;
    language?: string;
    ssr?: boolean;
    webp?: boolean;
  },
) {
  const { pathArrows = false, errorMsg = "", patch, language, ssr = false, webp = false } = options || {};

  if (!items || (items.length === 1 && items[0] === 0)) {
    return <div className="item-error-msg">{errorMsg}</div>;
  }

  const stackedItems = stackDuplicateItems(items);

  const list = stackedItems.map((item, index) => {
    return (
      <React.Fragment key={index}>
        <div className="image-wrapper">
          <Item
            className="sprite"
            itemId={item.id}
            spriteSize={spriteSize}
            isSprite
            patch={patch}
            language={language}
            ssr={ssr}
            webp={webp}
          />
          {item.count > 1 && <div className="label">{item.count}</div>}
        </div>
        {pathArrows && index !== stackedItems.length - 1 && <ArrowRight className="path-arrow" />}
      </React.Fragment>
    );
  });

  return list;
}
