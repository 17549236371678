import React from "react";
import { useGlobal } from "reactn";
import { useTranslation } from "react-i18next";
import { MediaQuery } from "@outplayed/responsive";
import { Item } from "@outplayed/tooltips";
import { stackDuplicateItems, displayItems } from "@ugg/shared/utils/item-helpers";
import { getPercetange, numberWithCommas } from "@ugg/shared/utils/math";
import { OverviewInfo, ItemOption } from "@ugg/shared/api/data-parser/champions/overview";

interface CommonProps extends ItemsProps {
  className?: string;
  dupeStartingItems:
    | (Omit<NonNullable<OverviewInfo["rec_starting_items"]>, "ids"> & { ids: ReturnType<typeof stackDuplicateItems> })
    | null;
}

interface ItemsProps {
  startingItems: OverviewInfo["rec_starting_items"];
  coreItems: OverviewInfo["rec_core_items"];
  items1: OverviewInfo["item_options_1"];
  items2: OverviewInfo["item_options_2"];
  items3: OverviewInfo["item_options_3"];
  patch: string;
}

export default function Items(props: ItemsProps) {
  const { startingItems } = props;
  const dupeStartingItems = stackDuplicateItems(startingItems?.ids || []);
  const commonProps: CommonProps = {
    ...props,
    dupeStartingItems: startingItems?.ids ? { ...startingItems, ids: dupeStartingItems } : null,
  };

  return (
    <React.Fragment>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
        <Desktop {...commonProps} />
      </MediaQuery>
      <MediaQuery min="TABLET" max="DESKTOP_SMALL">
        <DesktopSmall {...commonProps} />
      </MediaQuery>
      <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
        <Desktop {...commonProps} />
      </MediaQuery>
    </React.Fragment>
  );
}

function getItemProps(patch: string, language: string) {
  return {
    className: "item-img",
    spriteSize: 36,
    isSprite: true,
    patch,
    ssr: true,
    language,
    webp: true,
  };
}

const CoreItemsPath = (props: { coreItems: CommonProps["coreItems"]; itemProps: ReturnType<typeof getItemProps> }) => {
  const { t } = useTranslation();
  const { coreItems, itemProps } = props;
  const { spriteSize } = itemProps;

  const ids = coreItems?.ids || [];

  return (
    <React.Fragment>
      <div className="flex items-center justify-center gap-[6px]">
        {displayItems(ids, spriteSize, { ...itemProps, pathArrows: true })}
      </div>
      <div className="item-stats">
        {coreItems && (
          <>
            <div className="winrate">{`${getPercetange(coreItems.wins, coreItems.matches, 2)}% WR`}</div>
            <div className="matches">{`${numberWithCommas(coreItems.matches)} ${t("Matches")}`}</div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const ItemDupe = (
  props: {
    itemId: number;
    count: number;
  } & ReturnType<typeof getItemProps>,
) => {
  const { itemId, count, ...otherProps } = props;

  return (
    <div className="item-dupe">
      <Item {...otherProps} itemId={itemId} />
      {count > 1 && <div className="count-label">{count}</div>}
    </div>
  );
};

const ItemOptions = (props: { items: ItemOption[]; patch: string }) => {
  const { items, patch } = props;
  const { t } = useTranslation();
  const [language] = useGlobal("language");
  return (
    <>
      {items.map((item, index) => {
        return (
          <div key={index} className="item-option">
            <Item className="item-img" itemId={item.id} spriteSize={36} isSprite patch={patch} ssr language={language} webp />
            <div className="item-stats">
              <div className="winrate">{`${item.win_rate}% WR`}</div>
              <div className="matches">{`${numberWithCommas(item.matches)} ${t("Matches")}`}</div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const DesktopSmall = (props: CommonProps) => {
  const { t } = useTranslation();
  const [language] = useGlobal("language");
  const { className, dupeStartingItems, coreItems, items1, items2, items3, patch } = props;

  return (
    <div className={`content-section content-section_no-padding recommended-build_items ${className}`}>
      <div className="content-section_content starting-items">
        <div className="content-section_header">{t("Starting & Core Items")}</div>
        <div>
          <div className="flex items-center justify-center">
            {dupeStartingItems?.ids.map((item, index) => (
              <ItemDupe {...getItemProps(patch, language)} key={index} count={item.count} itemId={item.id} />
            ))}
          </div>
          <div className="item-stats">
            {dupeStartingItems?.matches !== undefined && (
              <>
                <div className="winrate">{`${getPercetange(dupeStartingItems.wins, dupeStartingItems.matches, 2)}% WR`}</div>
                <div className="matches">{`${numberWithCommas(dupeStartingItems.matches)} ${t("Matches")}`}</div>
              </>
            )}
          </div>
        </div>
        {coreItems && coreItems.matches > 0 && (
          <div style={{ marginTop: 12 }} className="core-items">
            <CoreItemsPath coreItems={coreItems} itemProps={getItemProps(patch, language)} />
          </div>
        )}
      </div>
      <div className="content-section_content item-options item-options-1">
        <div className="content-section_header">{t("Fourth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items1 || []} patch={patch} />
        </div>
      </div>
      <div className="content-section_content item-options item-options-2">
        <div className="content-section_header">{t("Fifth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items2 || []} patch={patch} />
        </div>
      </div>
      <div className="content-section_content item-options item-options-3">
        <div className="content-section_header">{t("Sixth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items3 || []} patch={patch} />
        </div>
      </div>
    </div>
  );
};

const Desktop = (props: CommonProps) => {
  const { t } = useTranslation();
  const [language] = useGlobal("language");
  const { className, dupeStartingItems, coreItems, items1, items2, items3, patch } = props;

  return (
    <div className={`content-section content-section_no-padding recommended-build_items ${className}`}>
      <div className="content-section_content starting-items">
        <div className="content-section_header">{t("Starting Items")}</div>
        <div>
          <div className="flex items-center justify-center">
            {dupeStartingItems?.ids.map((item, index) => (
              <ItemDupe {...getItemProps(patch, language)} key={index} count={item.count} itemId={item.id} />
            ))}
          </div>
          <div className="item-stats">
            {dupeStartingItems?.matches !== undefined && (
              <>
                <div className="winrate">{`${getPercetange(dupeStartingItems.wins, dupeStartingItems.matches, 2)}% WR`}</div>
                <div className="matches">{`${numberWithCommas(dupeStartingItems.matches)} ${t("Matches")}`}</div>
              </>
            )}
          </div>
        </div>
        <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
          <span className="content-section_blurb">{t("Best for most matchups")}</span>
        </MediaQuery>
      </div>
      <div className="content-section_content core-items mythic-border-container">
        <div>
          <div className="content-section_header">{t("Core Items")}</div>
          <div>
            {coreItems && coreItems.matches > 0 && (
              <CoreItemsPath coreItems={coreItems} itemProps={getItemProps(patch, language)} />
            )}
          </div>
        </div>
        <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
          <span className="content-section_blurb">{t("Build this every game")}</span>
        </MediaQuery>
      </div>
      <div className="content-section_content item-options item-options-1">
        <div className="content-section_header">{t("Fourth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items1 || []} patch={patch} />
        </div>
        <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
          <span className="content-section_blurb">{t("Options after core build")}</span>
        </MediaQuery>
      </div>
      <div className="content-section_content item-options item-options-2">
        <div className="content-section_header">{t("Fifth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items2 || []} patch={patch} />
        </div>
      </div>
      <div className="content-section_content item-options item-options-3">
        <div className="content-section_header">{t("Sixth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items3 || []} patch={patch} />
        </div>
      </div>
    </div>
  );
};
